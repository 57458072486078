import { Avatar, Menu, ActionIcon, Text } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { TbLogout } from "react-icons/tb";

export default function UserButton() {
	const auth = useAuth0();

	return (
		<Menu>
			<Menu.Target>
				<Avatar
					src={null}
					component={ActionIcon}
					variant={"filled"}
					styles={() => ({
						placeholder: {
							backgroundColor: "var(--pkf-blue-light)",
							"&:hover": {backgroundColor: "rgba(var(--pkf-blue-light-rgb), 0.5)"}
						},
						root: {
							"&:hover": {backgroundColor: "rgba(var(--pkf-blue-light-rgb), 0.5)"}
						}
					})}
				/>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Label>
					<Text size={16}>{auth.user?.name}</Text>
					<Text size={12}>{auth.user?.email}</Text>
				</Menu.Label>

				<Menu.Divider/>

				<Menu.Item icon={<TbLogout size={14}/>} onClick={() => auth.logout()}>
					Log out
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}