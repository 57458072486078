import React, { PropsWithChildren, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Center, Loader, Stack, Text } from "@mantine/core";
import { Config } from "../helpers/config";
import { SurveyAPIModel } from "../models/generalResults.model";
import { cleanseFilename, ParseGeneralFormResult } from "../helpers/helpers";
import { IAttachment, IAttachmentPath } from "../components/fields/attachments/attachments";
import { showNotification } from "@mantine/notifications";

export interface IResultString {
	result: string;
}

export interface IApiError {
	title: string;
	detail: string;
}

export function onError(e: IApiError) {
	console.log(e)
	const message = (
		<Stack>
			<Text>{e.title}</Text>
			<Text italic color={"dimmed"} size={"sm"}>{e.detail}</Text>
		</Stack>
	);
	showNotification({message, color: "red"});
}

export interface IApiContext {
	LogAuth(): Promise<void>;

	GetSurveyResults(): Promise<SurveyAPIModel[]>;

	GetSurveyResultById(id: string): Promise<SurveyAPIModel>;

	UpdateSurvey(item: SurveyAPIModel): Promise<SurveyAPIModel>;

	CompleteSurvey(id: number): Promise<SurveyAPIModel>;

	RemoveSurveyFile(data: IAttachmentPath): Promise<void>;

	AddSurveyFile(data: IAttachment): Promise<string>;

	AddAVIEmployeeFile(data: IAttachment): Promise<SurveyAPIModel>;

	DeleteEmployees(id: number): Promise<SurveyAPIModel>;

}

export const ApiContext = React.createContext<IApiContext>(
	{...{} as IApiContext}
);

export function ApiProvider(props: PropsWithChildren) {
	const [token, setToken] = useState<string>();
	const [loading, setLoading] = useState(true);

	const {
		isAuthenticated,
		isLoading,
		getAccessTokenSilently,
		loginWithRedirect,
		user,
		error
	} = useAuth0();

	useEffect(() => {
		if (error)
			//raygun.sendError(getError(error), {}, ["Auth0"]);
			console.log("API Context Error", error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		async function getToken() {
			const accessToken = await getAccessTokenSilently({
				authorizationParams: {
					audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
					scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE
				}
			});
			setToken(accessToken);
			setLoading(false);
		}

		if (!isLoading && isAuthenticated) {
			getToken();
		}

		if (!isLoading && !isAuthenticated) {
			loginWithRedirect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isAuthenticated]);

	useEffect(() => {
		if (token)
			LogAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])

	async function LogAuth() {
		await GetResponse<void>("POST", "auth");
	}

	async function GetSurveyResults() {
		const json = await GetResponse<SurveyAPIModel[]>("GET", "Surveys");
		return json.map(i => ParseGeneralFormResult(i));
	}

	async function GetSurveyResultById(id: string) {
		const json = await GetResponse<SurveyAPIModel>("GET", `Surveys/${id}`);
		return ParseGeneralFormResult(json);
	}

	async function UpdateGeneralForm(item: SurveyAPIModel) {
		const json = await GetResponse<SurveyAPIModel>("PUT", `Surveys`, item);
		return ParseGeneralFormResult(json);
	}

	async function CompleteGeneralForm(id: number) {
		const json = await GetResponse<SurveyAPIModel>("POST", `Surveys/complete?formId=${id}`);
		return ParseGeneralFormResult(json);
	}

	async function RemoveFile({formId, filePath}: IAttachmentPath) {
		await GetResponse<string>("DELETE", `attachments?formId=${formId}&path=${filePath}`);
	}

	async function AddFile({formId, field, file}: IAttachment) {
		const formData = new FormData();
		formData.append("file", file, cleanseFilename(file.name));
		const result = await GetResponse<IResultString, FormData>("POST", `attachments?formId=${formId}&field=${field}`, formData);
		return result.result;
	}

	async function AddAVIEmployeeFile({formId, field, file}: IAttachment) {
		const formData = new FormData();
		formData.append("file", file, cleanseFilename(file.name));
		return await GetResponse<SurveyAPIModel, FormData>("POST", `attachments/avi?formId=${formId}&field=${field}`, formData);
	}

	async function DeleteEmployees(id: number) {
		const json = await GetResponse<SurveyAPIModel>("DELETE", `attachments/avi?formId=${id}`);
		return ParseGeneralFormResult(json);
	}

	async function GetResponse<T, TData = T>(method: string, endpoint: string, data?: TData) {
		const isFormData = data instanceof FormData;
		const body = data ?
			isFormData
				? data
				: JSON.stringify(data)
			: undefined;

		const headers = new Headers();
		headers.append("Authorization", `Bearer ${token}`);
		if (!isFormData) {
			headers.append("Accept", `application/json`);
			headers.append("Content-Type", `application/json`);
		}

		return await fetch(Config.API_URL + endpoint, {
			method,
			body,
			headers
		})
			.then(async (response) => {
				if (response.ok) {
					let text = await response.text();
					return JSON.parse(text) as T;
				} else {
					let error = await response.json();
					error = getError(error);
					//raygun.sendError(error, {method, endpoint, data});
					throw error;
				}
			})
			.catch(async (error) => {
				error = getError(error);
				//raygun.sendError(error, {method, endpoint, data});
				throw error;
			});
	}

	function getError(error: any): IApiError {
		if (error.message) {
			error = {title: "An error occurred", detail: error.message};
		} else if (error.error) {
			error = {title: "An error occurred", detail: error.error};
		}
		return error;
	}

	if (loading) {
		return (
			<Center style={{position: "fixed", inset: 0}}>
				<Loader/>
			</Center>
		);
	}

	return (
		<ApiContext.Provider
			value={{
				LogAuth,
				GetSurveyResults: GetSurveyResults,
				GetSurveyResultById: GetSurveyResultById,
				UpdateSurvey: UpdateGeneralForm,
				CompleteSurvey: CompleteGeneralForm,
				AddSurveyFile: AddFile,
				AddAVIEmployeeFile: AddAVIEmployeeFile,
				RemoveSurveyFile: RemoveFile,
				DeleteEmployees: DeleteEmployees
			}}
		>
			{props.children}
		</ApiContext.Provider>
	);
}