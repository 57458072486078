import "../styles/globals.css";
import type { AppProps } from "next/app";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import Head from "next/head";
import { MantineProvider } from "@mantine/core";
import { Config } from "../helpers/config";
import { Notifications } from "@mantine/notifications";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApiProvider } from "../context/api.context";
import loc from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import Offline from "./offline";
import { Analytics } from "@vercel/analytics/react";

dayjs.extend(loc);
const queryClient = new QueryClient();

export default function App(props: AppProps) {
	const { Component, pageProps } = props;
	const router = useRouter();

	async function onRedirectCallback(appState?: AppState) {
		await router.replace(appState?.returnTo || "/");
	}

	return (
		<>
			<Head>
				<title>{Config.title}</title>
				<link rel="icon" href="/favicon.ico"/>
				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width"/>
			</Head>

			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					colorScheme: "light",
				}}
			>
				<Notifications/>
				<Auth0Provider
					domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
					clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
					authorizationParams={{
						redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
						scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE || "",
						audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
					}}
					onRedirectCallback={onRedirectCallback}
				>
					<QueryClientProvider client={queryClient}>
						<ApiProvider>
							{/*<Component {...pageProps} />*/}
							<Offline/>
							<Analytics/>
						</ApiProvider>
					</QueryClientProvider>
				</Auth0Provider>
			</MantineProvider>
		</>
	);
}
