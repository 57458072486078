import { Config } from "../../helpers/config";
import { Divider, MediaQuery, Navbar, NavLink, Text, Title } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import styles from "./navBar.module.scss";

interface INavBarProps {
	opened: boolean;
}

export default function NavBar(props: INavBarProps) {
	const router = useRouter();
	return (
		<Navbar
			p={"md"}
			hiddenBreakpoint={"sm"}
			hidden={!props.opened}
			width={{ sm: 150, lg: 150 }}
		>
			<MediaQuery largerThan={"sm"} styles={{ display: "none" }}>
				<div>
					<Navbar.Section mt={"xs"}>
						<Title order={3}>{Config.title}</Title>
					</Navbar.Section>
					<Divider my={"sm"} />
				</div>
			</MediaQuery>


			<Navbar.Section mt={"xs"}>
				<Link href={"/"} passHref>
					<NavLink
						label={"Forms"}
						active={router.pathname === "/"}
					/>
				</Link>
				<Link href={"/help"} passHref>
					<NavLink
						label={"Help"}
						active={router.pathname === "/help"}
					/>
				</Link>
				<div className={styles.logoContainer}>
					<Text 
						size={"sm"} 
						weight={"bold"}
						className={styles.logoTopLine}
					>
						Technology by
					</Text>
					<Image
							src={"/pkf_logo_primary.png"}
							alt={"PKF Logo"}
							width={200}
							height={50}
							className={styles.image}
						/>
				</div>
			</Navbar.Section>
		</Navbar>
	);
}