import {
	SurveyAPIModel,
} from "../models/generalResults.model";
import { FormErrors } from "@mantine/form/lib/types";
import { SelectItem } from "@mantine/core";

export function ParseGeneralFormResult(item: SurveyAPIModel) {
	return ({
		...item,
		created: item.created ? new Date(item.created) : null,
		modified: item.modified ? new Date(item.modified) : null,
		completedDate: item.completed ? new Date(item.completed) : null,
	}) as SurveyAPIModel;
}

/**
 * RegEx of special characters to remove from a string
 */
const specialChars = /[!@#$%^&*'"]/g;

/**
 * Removes characters from a filename that exist in the special characters regex
 * @param filename string to test
 */
export function cleanseFilename(filename: string) {
	return filename.replace(specialChars, "");
}

export function GetValidationTotals<T extends object>(errors: T) {
	const errorKeys = Object.keys(errors) as (keyof T)[];
	const totalCount = errorKeys.length;
	const completeCount = errorKeys.filter((i) => errors[i] === null).length;

	return ({
		totalCount,
		completeCount,
		errors: (errors as FormErrors)
	});
}

export const ValidationText = {
	EnterValue: "Please enter a value",
	EnterNumber: "Please enter a number",
	EnterEmail: "Please enter a valid email",
	SelectValue: "Please select a value",
	SelectFile: "Please select a file",
	SelectDate: "Please select a date",
};

export function GetSelectOptions(data: string[]): SelectItem[] {
	return data.map(i => ({value: i, label: i}))
}

export const IndustrialInstrumentValues = [
	"Fast Food Industry Award",
	"Other"
];

export const PayrollSystemValues = [
	"Xero",
	"MYOB",
	"Sage",
	"Other"
];

export const ATOReportingScheduleValues = [
	"Quarterly",
	"Monthly"
];


export const PerformanceReviewPeriodValues = [
	"Weekly",
	"Monthly",
	"Quarterly",
	"Bi-Yearly",
	"Yearly",
	"Other",
];

export const PayslipMechanismValues = [
	"Electronically",
	"Hard copy",
	"Other",
];

export const VisaTypeValues = [
	"Student",
	"Tourist",
	"Working Holiday",
	"Other(provide value)",
]