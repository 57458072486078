import { AppShell } from "@mantine/core";
import { PropsWithChildren, useState } from "react";
import NavBar from "../navBar/navBar";
import AppHeader from "../appHeader/appHeader";

export default function AppWrapper(props: PropsWithChildren) {
	const [opened, setOpened] = useState(false);

	return (
		<AppShell
			padding={"md"}
			styles={(theme) => ({
				main: {backgroundColor: theme.colors.gray[0]},
			})}
			header={<AppHeader opened={opened} setOpened={setOpened}/>}
			navbar={<NavBar opened={opened}/>}
			navbarOffsetBreakpoint={"sm"}
		>
			{props.children}
		</AppShell>
	);
}