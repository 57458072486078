import { Config } from "../../helpers/config";
import { Burger, Header, MantineTheme, MediaQuery, Title, useMantineTheme } from "@mantine/core";
import UserButton from "../userButton/userButton";
import styles from "./appHeader.module.scss";
import { SetStateAction } from "react";
import Image from "next/image";

interface IAppHeaderProps {
	opened: boolean;

	setOpened(open: SetStateAction<boolean>): void;
}

export default function AppHeader(props: IAppHeaderProps) {
	const theme = useMantineTheme();

	return (
		<Header
			height={60}
			p={"xs"}
			styles={(theme: MantineTheme) => ({
				root: {backgroundColor: "var(--pkf-blue)", color: theme.colors.gray[0]}
			})}
		>
			<div className={styles.container}>
				<MediaQuery largerThan={"sm"} styles={{display: 'none'}}>
					<Burger
						opened={props.opened}
						onClick={() => props.setOpened((o) => !o)}
						size={"sm"}
						color={theme.colors.gray[0]}
						mr={"xl"}
					/>
				</MediaQuery>
				<Image
					src={"/craveablelogo.webp"}
					alt={"Craveable Brands Logo"}
					width={200}
					height={50}
					className={styles.image}
				/>

				<MediaQuery smallerThan={"sm"} styles={{display: "none"}}>
					<Title order={3} style={{userSelect: "none"}}>{Config.title}</Title>
				</MediaQuery>

				<div className={styles.content}>
					<UserButton/>
				</div>
			</div>
		</Header>
	);
}