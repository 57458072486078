import AppWrapper from "../components/appWrapper/appWrapper";
import { Text, Title } from "@mantine/core";

export default function Offline() {
	return (
		<AppWrapper>
			<Title order={1}>Closed</Title>
			<Text py={"md"}>2023 Workplace Compliance Survey is now closed.</Text>
		</AppWrapper>
	);
}